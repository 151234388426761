@import "../assets/variables";

.copy-button {
    position:absolute;
    width:35px;
    height:35px;
    overflow:hidden;
    left: -35px;
    border-radius: 10px 0 0 10px;
    top: 0;
    bottom: 0;
    margin:auto;
    border: 1px solid $primary;
    border-right: none;
    line-height: 35px;
    vertical-align:middle;
    color:$primary;
    cursor:pointer;
    &:hover {
        background: $primary;
        color:white;
    }
}